import React, {Component} from "react";
import {Form, Input, Select, TextArea, Container} from "semantic-ui-react";
import AuthDataProvider from "../../../Services/AuthDataProvider";
import { v4 as uuidv4 } from 'uuid';
import ContingentButton from "../../ContingentButton";

AuthDataProvider.init({baseURL: process.env.SYNAPSE_CORE_URL});


export default class StrapiiTokenPane extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jti: uuidv4(),
            mvpd: "",
            requestor: "",
            streamUrlsClob: "",
            entitledNetworksClob:"",
            productsClob: "",
            secret: "",
            concurrencyLimit: "",
            concurrencySilo: "",
            entitlementsClob:"",
            entitlements: [],
            streamUrls: [],
            mediaIdsClob: "",
            mediaIds: [],
            network: "",
            ttl: 300
        };
    }

    isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    getStrapiiToken = async () => {
        if (!this.props.hasOwnProperty("user") || !this.props.user.hasOwnProperty("sub")) {
            this.props.toast("The user making this request could not be identified.");
        }

        let {jti, mvpd, requestor, network, concurrencyLimit, concurrencySilo, streamUrlsClob, mediaIdsClob, entitledNetworksClob, entitlementsClob, productsClob, ttl} = this.state;

        if (ttl > 86400) {
            const confirmation = confirm("You are about to create a Token with a really long expiration, this is very insecure and if leaked could adversely affect our brands.");
            if (confirmation !== true) {
                return;
            }
        }

        if (ttl < 300 || !ttl) {
            ttl = 300;
        }

        if (!jti) {
            this.props.toast("JTI is a required field.");
            return;
        }

        if (!network) {
            this.props.toast("Network is a required field!");
            return;
        }

        const now = parseInt(new Date().getTime() / 1000);

        const body = {
            user: this.props.user.sub,
            jti,
            ...mvpd.length > 0 && {mvpd},
            ...requestor.length > 0 && {requestor},
            network,
            iat: now,
            exp: now + ttl,
            ...concurrencyLimit && this.isNumeric(concurrencyLimit) && {concurrencyLimit: parseInt(concurrencyLimit)},
            ...concurrencySilo && {concurrencySilo},
            ...streamUrlsClob && {urls: streamUrlsClob.toString().split("\n")},
            ...mediaIdsClob && {media: mediaIdsClob.toString().split("\n")},
            ...entitledNetworksClob && {entitledNetworks: entitledNetworksClob.toString().split("\n")},
            ...entitlementsClob && {entitlements: entitlementsClob.toString().split("\n")},
            ...productsClob && {products: productsClob.toString().split("\n")},
        };

        AuthDataProvider.getStrapiiToken(body).then(response => {
            console.log("StrapiiTokenPane.getStrapiiToken response: ", response);
            this.props.onFormSubmit(response);
        }).catch(error => {
            console.error(error);
            this.props.toast("error", "There was an error creating a strapii token with the provided data.", "error");
        });
    };

    render() {
        return (
            <Container fluid style={{maxHeight: "82vh", overflowY: "auto", overflowX: "hidden"}}>
                <Form>
                    <Form.Field>
                        <label>Generate JWS Token</label>
                    </Form.Field>
                    <Form.Field
                        label="JTI"
                        control={Input}
                        onChange={(event, {value}) => this.setState({jti: value})}
                        value={this.state.jti}
                        required
                    />
                    <Form.Field
                        label="MVPD (e.g. turnernow, mss-me-core, etc.)"
                        control={Input}
                        value={this.state.mvpd}
                        onChange={(event, {value}) => this.setState({mvpd: value})}
                    />
                    <Form.Field
                        label="Requestor"
                        control={Input}
                        value={this.state.requestor}
                        onChange={(event, {value}) => this.setState({requestor: value})}
                    />
                    <Form.Field
                        label="Network (e.g. WMC, TBS, MML, etc.)"
                        control={Input}
                        value={this.state.network}
                        onChange={(event, {value}) => this.setState({network: value})}
                        required
                    />
                    <Form.Field
                        label="Stream URLs"
                        control={TextArea}
                        value={this.state.streamUrlsClob}
                        onChange={(event, {value}) => this.setState({streamUrlsClob: value})}
                    />
                    <Form.Field
                        label="Media IDs"
                        control={TextArea}
                        value={this.state.mediaIdsClob}
                        onChange={(event, {value}) => this.setState({mediaIdsClob: value})}
                    />
                    <Form.Field
                        label="Entitlements"
                        control={TextArea}
                        value={this.state.entitlementsClob}
                        onChange={(event, {value}) => this.setState({entitlementsClob: value})}
                    />
                    <Form.Field
                        label="Entitled Networks"
                        control={TextArea}
                        value={this.state.entitledNetworksClob}
                        onChange={(event, {value}) => this.setState({entitledNetworksClob: value})}
                    />
                    <Form.Field
                        label="Products"
                        control={TextArea}
                        value={this.state.productsClob}
                        onChange={(event, {value}) => this.setState({productsClob: value})}
                    />
                    <Form.Field
                        label="Concurrency Limit"
                        control={Input}
                        value={this.state.concurrencyLimit}
                        onChange={(event, {value}) => this.setState({concurrencyLimit: value})}
                    />
                    <Form.Field
                        label="Concurrency Silo"
                        control={Input}
                        value={this.state.concurrencySilo}
                        onChange={(event, {value}) => this.setState({concurrencySilo: value})}
                    />
                    <Form.Field
                        label="TTL"
                        control={Select}
                        value={this.state.ttl}
                        options={[
                            {key: "5min", text: "5 Minutes", value: 300},
                            {key: "1hour", text: "1 Hour", value: 3600},
                            {key: "8hour", text: "8 Hours", value: 28800},
                            {key: "1day", text: "1 Day", value: 86400},
                            {key: "7days", text: "7 Days", value: 604800},
                            {key: "30days", text: "30 Days", value: 2592000},
                            {key: "90days", text: "90 Days", value: 7776000}
                        ]}
                        onChange={(event, {value}) => this.setState({ttl: value})}
                        required
                    />
                    <ContingentButton
                        onClick={this.getStrapiiToken}
                        allPermissions={this.props.permissions}
                        module={this.props.module}
                        scope="strapii"
                        service={this.props.service}
                        user={this.props.user}
                    > Generate </ContingentButton>
                </Form>
            </Container>
        );
    }
}
