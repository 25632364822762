import React from 'react';
import PropTypes from "prop-types";
import TokenInfoViewer from "./TokenInfoViewer";
import TokenISPPane from "./TokenISPPane";
import TokenSPEPane from "./TokenSPEPane";
import {Grid, Divider, Tab} from "semantic-ui-react";
import SecureTabPane from "../../SecureTabPane";
import TokenISPViewPane from "./TokenISPViewPane";
import TokenAssetsPane from "./TokenAssetsPane";
import TokenJWSPane from "./TokenJWSPane";
import TokenJWSv3Pane from "./TokenJWSv3Pane";
import TokenATTPane from "./TokenATTPane";
import TokenATTV2Pane from "./TokenATTV2Pane";
import TokenTurnerPane from "./TokenTurnerPane";
import TokenRefreshPane from "./TokenRefreshPane";
import TokenNGPane from "./TokenNGPane";
import StrapiiTokenPane from "./StrapiiTokenPane";
import TokenAdminDataProvider from "../../../Services/TokenAdminDataProvider";

class TokenAdminMasterView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mediaId: "",
            message: "",
            isLoading: true
        };
        console.log("TokenAdminMasterView.constructor: props: ", this.props);
    }

    handleSubmit = message => {
        this.setState({
            message: message
        });
    };

    render() {
        return (
            <Grid style={{height: '100vh'}}>
                <Grid.Row>
                    <Grid.Column width={10} className="masterContainer">
                        <Grid.Row>
                            <Tab
                                onTabChange={() => this.setState({message: ""})}
                                menu={{vertical: true, fluid: true, tabular: true}}
                                renderActiveOnly
                                panes={[
                                    {
                                        menuItem: "SPE",
                                        render: () =>
                                            <SecureTabPane
                                                component={
                                                    <TokenSPEPane
                                                        user={this.props.user}
                                                        toast={this.props.toast}
                                                        onFormSubmit={this.handleSubmit}
                                                        module={this.props.module}
                                                        permissions={this.props.permissions}
                                                        service={this.props.service}
                                                        userPermissions={this.props.userPermissions}
                                                        TokenAdminDataProvider={TokenAdminDataProvider}
                                                    />
                                                }
                                                authState={this.props.authState}
                                                onFormSubmit={{}}
                                                user={this.props.user}
                                                authenticated={this.props.authenticated}
                                                checkIfAuthorized={this.props.checkIfAuthorized}
                                                module={this.props.module}
                                                permissions={this.props.permissions}
                                                service={this.props.service}
                                                userPermissions={this.props.userPermissions}
                                                loadingPermissions={this.props.loadingPermissions}
                                                scope="spe"
                                            />
                                    },
                                    {
                                        menuItem: "ISP",
                                        render: () =>
                                            <SecureTabPane
                                                component={
                                                    <TokenISPPane
                                                        user={this.props.user}
                                                        toast={this.props.toast}
                                                        onFormSubmit={this.handleSubmit}
                                                        module={this.props.module}
                                                        permissions={this.props.permissions}
                                                        service={this.props.service}
                                                        userPermissions={this.props.userPermissions}
                                                        TokenAdminDataProvider={TokenAdminDataProvider}
                                                    />
                                                }
                                                onFormSubmit={{}}
                                                authState={this.props.authState}
                                                user={this.props.user}
                                                authenticated={this.props.authenticated}
                                                checkIfAuthorized={this.props.checkIfAuthorized}
                                                module={this.props.module}
                                                permissions={this.props.permissions}
                                                service={this.props.service}
                                                userPermissions={this.props.userPermissions}
                                                loadingPermissions={this.props.loadingPermissions}
                                                scope="isp"
                                            />
                                    },
                                    {
                                        menuItem: "JWS v2",
                                        render: () =>
                                            <SecureTabPane
                                                component={
                                                    <TokenJWSPane
                                                        user={this.props.user}
                                                        toast={this.props.toast}
                                                        onFormSubmit={this.handleSubmit}
                                                    />
                                                }
                                                authState={this.props.authState}
                                                user={this.props.user}
                                                authenticated={this.props.authenticated}
                                                checkIfAuthorized={this.props.checkIfAuthorized}
                                                module={this.props.module}
                                                permissions={this.props.permissions}
                                                service={this.props.service}
                                                userPermissions={this.props.userPermissions}
                                                loadingPermissions={this.props.loadingPermissions}
                                                scope="jws"
                                                role="editor"
                                            />
                                    },
                                    {
                                        menuItem: "JWS v3",
                                        render: () =>
                                            <SecureTabPane
                                                component={
                                                    <TokenJWSv3Pane
                                                        user={this.props.user}
                                                        toast={this.props.toast}
                                                        onFormSubmit={this.handleSubmit}
                                                    />
                                                }
                                                authState={this.props.authState}
                                                user={this.props.user}
                                                authenticated={this.props.authenticated}
                                                checkIfAuthorized={this.props.checkIfAuthorized}
                                                module={this.props.module}
                                                permissions={this.props.permissions}
                                                service={this.props.service}
                                                userPermissions={this.props.userPermissions}
                                                loadingPermissions={this.props.loadingPermissions}
                                                scope="jws"
                                                role="editor"
                                            />
                                    },
                                    {
                                        menuItem: "Strapii",
                                        render: () =>
                                            <SecureTabPane
                                                component={
                                                    <StrapiiTokenPane
                                                        user={this.props.user}
                                                        toast={this.props.toast}
                                                        onFormSubmit={this.handleSubmit}
                                                        module={this.props.module}
                                                        permissions={this.props.permissions}
                                                        service={this.props.service}
                                                        userPermissions={this.props.userPermissions}
                                                    />
                                                }
                                                authState={this.props.authState}
                                                user={this.props.user}
                                                authenticated={this.props.authenticated}
                                                checkIfAuthorized={this.props.checkIfAuthorized}
                                                module={this.props.module}
                                                permissions={this.props.permissions}
                                                service={this.props.service}
                                                role="editor"
                                                userPermissions={this.props.userPermissions}
                                                loadingPermissions={this.props.loadingPermissions}
                                                scope="strapii"
                                            />
                                    },
                                    {
                                        menuItem: "AT&T",
                                        render: () =>
                                            <SecureTabPane
                                                component={
                                                    <TokenATTPane
                                                        user={this.props.user}
                                                        toast={this.props.toast}
                                                        onFormSubmit={this.handleSubmit}
                                                        module={this.props.module}
                                                        permissions={this.props.permissions}
                                                        service={this.props.service}
                                                        userPermissions={this.props.userPermissions}
                                                    />
                                                }
                                                authState={this.props.authState}
                                                user={this.props.user}
                                                authenticated={this.props.authenticated}
                                                checkIfAuthorized={this.props.checkIfAuthorized}
                                                module={this.props.module}
                                                permissions={this.props.permissions}
                                                service={this.props.service}
                                                userPermissions={this.props.userPermissions}
                                                loadingPermissions={this.props.loadingPermissions}
                                                scope="att"
                                            />
                                    },
                                    {
                                        menuItem: "AT&T V2",
                                        render: () =>
                                            <SecureTabPane
                                                component={
                                                    <TokenATTV2Pane
                                                        user={this.props.user}
                                                        toast={this.props.toast}
                                                        onFormSubmit={this.handleSubmit}
                                                        module={this.props.module}
                                                        permissions={this.props.permissions}
                                                        service={this.props.service}
                                                        userPermissions={this.props.userPermissions}
                                                    />
                                                }
                                                authState={this.props.authState}
                                                user={this.props.user}
                                                authenticated={this.props.authenticated}
                                                checkIfAuthorized={this.props.checkIfAuthorized}
                                                module={this.props.module}
                                                permissions={this.props.permissions}
                                                service={this.props.service}
                                                userPermissions={this.props.userPermissions}
                                                loadingPermissions={this.props.loadingPermissions}
                                                scope="att"
                                            />
                                    },
                                    {
                                        menuItem: "Turner",
                                        render: () =>
                                            <SecureTabPane
                                                component={
                                                    <TokenTurnerPane
                                                        user={this.props.user}
                                                        toast={this.props.toast}
                                                        onFormSubmit={this.handleSubmit}
                                                        module={this.props.module}
                                                        permissions={this.props.permissions}
                                                        service={this.props.service}
                                                        userPermissions={this.props.userPermissions}
                                                        TokenAdminDataProvider={TokenAdminDataProvider}
                                                    />
                                                }
                                                user={this.props.user}
                                                authState={this.props.authState}
                                                authenticated={this.props.authenticated}
                                                checkIfAuthorized={this.props.checkIfAuthorized}
                                                module={this.props.module}
                                                permissions={this.props.permissions}
                                                service={this.props.service}
                                                userPermissions={this.props.userPermissions}
                                                loadingPermissions={this.props.loadingPermissions}
                                                scope="turner"
                                            />
                                    },
                                    {
                                        menuItem: "NGTV",
                                        render: () =>
                                            <SecureTabPane
                                                component={
                                                    <TokenNGPane
                                                        user={this.props.user}
                                                        toast={this.props.toast}
                                                        onFormSubmit={this.handleSubmit}
                                                        module={this.props.module}
                                                        permissions={this.props.permissions}
                                                        service={this.props.service}
                                                        userPermissions={this.props.userPermissions}
                                                        TokenAdminDataProvider={TokenAdminDataProvider}
                                                    />
                                                }
                                                user={this.props.user}
                                                authState={this.props.authState}
                                                authenticated={this.props.authenticated}
                                                checkIfAuthorized={this.props.checkIfAuthorized}
                                                module={this.props.module}
                                                permissions={this.props.permissions}
                                                service={this.props.service}
                                                userPermissions={this.props.userPermissions}
                                                loadingPermissions={this.props.loadingPermissions}
                                                scope="ngtv"
                                            />
                                    },
                                    {
                                        menuItem: "Renew",
                                        render: () =>
                                            <SecureTabPane
                                                component={
                                                    <TokenRefreshPane
                                                        user={this.props.user}
                                                        toast={this.props.toast}
                                                        onFormSubmit={this.handleSubmit}
                                                        module={this.props.module}
                                                        permissions={this.props.permissions}
                                                        service={this.props.service}
                                                        userPermissions={this.props.userPermissions}
                                                        TokenAdminDataProvider={TokenAdminDataProvider}
                                                    />
                                                }
                                                user={this.props.user}
                                                authState={this.props.authState}
                                                authenticated={this.props.authenticated}
                                                checkIfAuthorized={this.props.checkIfAuthorized}
                                                module={this.props.module}
                                                permissions={this.props.permissions}
                                                service={this.props.service}
                                                userPermissions={this.props.userPermissions}
                                                loadingPermissions={this.props.loadingPermissions}
                                                scope={["spe", "isp", "strapii", "att", "turner", "ngtv"]}
                                            />
                                    },
                                    {
                                        menuItem: "ISP View",
                                        render: () =>
                                            <SecureTabPane
                                                component={
                                                    <TokenISPViewPane
                                                        user={this.props.user}
                                                        toast={this.props.toast}
                                                        onFormSubmit={this.handleSubmit}
                                                        TokenAdminDataProvider={TokenAdminDataProvider}
                                                    />
                                                }
                                                authState={this.props.authState}
                                                user={this.props.user}
                                                authenticated={this.props.authenticated}
                                                checkIfAuthorized={this.props.checkIfAuthorized}
                                                module={this.props.module}
                                                permissions={this.props.permissions}
                                                service={this.props.service}
                                                userPermissions={this.props.userPermissions}
                                                loadingPermissions={this.props.loadingPermissions}
                                                open
                                            />
                                    },
                                    {
                                        menuItem: "Assets",
                                        render: () =>
                                            <SecureTabPane
                                                component={
                                                    <TokenAssetsPane
                                                        user={this.props.user}
                                                        toast={this.props.toast}
                                                        onFormSubmit={this.handleSubmit}
                                                        TokenAdminDataProvider={TokenAdminDataProvider}
                                                    />
                                                }
                                                authState={this.props.authState}
                                                user={this.props.user}
                                                authenticated={this.props.authenticated}
                                                checkIfAuthorized={this.props.checkIfAuthorized}
                                                module={this.props.module}
                                                permissions={this.props.permissions}
                                                service={this.props.service}
                                                userPermissions={this.props.userPermissions}
                                                loadingPermissions={this.props.loadingPermissions}
                                                open
                                            />
                                    }
                                ]}/>
                        </Grid.Row>
                        <Divider horizontal />
                    </Grid.Column>
                    <Grid.Column width={6} className='detailsContainer'>
                        <div>
                            <TokenInfoViewer
                                toast={this.props.toast}
                                message={this.state.message}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

TokenAdminMasterView.propTypes = {
    user: PropTypes.object.isRequired,
    toast: PropTypes.func.isRequired,
    service: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    permissions: PropTypes.array.isRequired,
    userPermissions: PropTypes.array.isRequired,
    authState: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    authenticated: PropTypes.bool.isRequired,
    checkIfAuthorized: PropTypes.func.isRequired,
    loadingPermissions: PropTypes.bool.isRequired
};

export default TokenAdminMasterView;
