import React from "react";
import {Form, Container} from "semantic-ui-react";
import {JsonEditor as Editor} from 'jsoneditor-react';
import "jsoneditor/dist/jsoneditor.css";
import ContingentButton from "../../ContingentButton";

//eyJ2ZXIiOjEsInR5cCI6IkpXVCIsImVuYyI6IkExMjhHQ00ifQ.N4pJM0RLBqy_j6VuWRCi5Q.Qbui6fpBd3t3k3bsn1FTWHXSMJ76Z4KOn2r-9DErfQoEQWs20DEgKLPXKnCaLYnzVnFT6_4C3SywcDJk612fSLYcgDGzwjcRjXWsYdv3iULDfrB01SA0PFjCkV9b5uMK_Lz5dqqVOw4snQSbjsSM6_env7U1HTbBdG0EKUlgusB44fb36a4i6bvGm-JPN_yU0hy8.L9AMxuZSRbUEbNX-1aMXfQ
class TokenISPPane extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "assetId": "",                   // Required. Asset ID as configured in Token DB.
            "mediaId": "",                   // Required. Media ID as configured in Token DBs.
            "customDrmPolicy": "",  // Optional. JSON String corresponding to the Custom
            //   DRM Policy.
            "customDrmPolicyString": false,           // Optional. Default (false). Indicates if
            //   customDrmPolicy is provided.
            "deviceId": "hfge6jsgrj8",                 // Optional. Default (hfge6jsgrj8).
            "drmPolicy": "TurnerPolicy1",               // Optional. Default (TurnerPolicy1).
            "ttl": 120                   // Optional. TTL in seconds for generated token.
            //   Default (2min) and maximum allowed configured
            //   via metadata.
        };
    }


    componentWillUnmount() {
        console.log(`(componentWillUnmount) Started.`);
        if (this.jsonEditor) {
            this.jsonEditor.destroy;
        }
    }

    componentDidMount() {
        const options = {
            mode: 'text'
        };
        this.state.jsonEditor = new Editor(this.container, options);
    }

    generateISPToken = () => {
        let userId = null;
        try {
            userId = this.props.user.sub|| null;

            if(!userId ) {
                throw "No selected route and/or user can not be identified";
            }

           const ttl =  parseInt(this.state.ttl);
            if (ttl> 86400) {
                const confirmation = confirm("You are about to create a Token with a really long expiration, this is very insecure and if leaked could adversely affect our brands......We know who you are.");
                if (confirmation !== true) {
                    return;
                }
            }

            let assetid = this.state.assetId;
            if(!assetid) {
                this.props.toast("Asset ID is a required field!");
                return;
            }

            const body = {
                "userId": userId,
                "assetId": this.state.assetId,
                "customDrmPolicy": this.state.customDrmPolicy,
                "customDrmPolicyString": this.state.customDrmPolicyString ==="on",
                "deviceId": this.state.deviceId,
                "drmPolicy": this.state.drmPolicy,
                "ttl": ttl
            };

            this.props.TokenAdminDataProvider.getISPToken(body)
                .then(resp => {
                    resp.json().then(payload => {
                        this.props.onFormSubmit(payload);
                    }).catch(error =>{
                        this.props.toast("Getting Payload failed");
                        console.log(error);
                    });
                });
        } catch(error) {
            console.log(error);
            this.props.toast("Getting Payload failed")
        }
    };

    handleChange = id => event => {
        let text = event.target.value;
        this.setState({
            [id]: text
        });
    };

    handleJSONChange = id => event => {
        let text = event;
        this.setState({
            [id]: text
        });
    };

    render() {
        return (
            <Container fluid style={{maxHeight: "82vh", overflowY: "auto", overflowX: "hidden"}}>
                <Form>
                    <Form.Field>
                        <label>Generate ISP Token</label>
                    </Form.Field>
                    <Form.Field>
                        <label>Asset ID</label>
                        <input
                            type="text"
                            placeholder="*Asset ID"
                            onChange={this.handleChange("assetId")}
                            id="assetIdInput"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Device ID</label>
                        <input
                            type="text"
                            onChange={this.handleChange("deviceId")}
                            id="deviceIdInput"/>
                    </Form.Field>
                    <Form.Field>
                        <label>DRM Policy</label>
                        <select id="drmPolicyDropDown" onChange={this.handleChange("drmPolicy")}>
                            <option value='TurnerPolicy1'>Turner Policy 1</option>
                            <option value="TurnerPolicy1L3">Turner Policy 1L3</option>
                            <option value="10minLeasePolicyL3">10 Minute Lease Policy L3</option>
                            <option value="10minLeasePolicy">10 Minute Lease Policy</option>
                            <option value="customDrmPolicy">customDrmPolicy</option>
                        </select>
                    </Form.Field>
                    <Form.Field>
                        <label>TTL</label>
                        <select id="ttlDropDown" onChange={this.handleChange("ttl")}>
                            <option value='120'>2 Minutes</option>
                            <option value='300'>5 Minutes</option>
                            <option value="3600">1 Hour</option>
                            <option value="28800">8 Hours</option>
                            <option value="86400">24 Hours</option>
                            <option value="604800">1 Week</option>
                            <option value="2592000">1 Month</option>
                            <option value="7776000">3 Months</option>
                        </select>
                    </Form.Field>
                    <Form.Field>
                        <label>Custom DRM Policy String</label>
                        <input
                            type="checkbox"
                            onChange={this.handleChange("customDrmPolicyString")}
                            id="customDrmPolicyStringCB"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Custom DRM Policy</label>
                        <Editor style={"width: 400px; height: 400px;"}
                                id="customDrmPolicy"
                                mode={"text"}
                                value={{}}
                                search={false}
                                onChange={this.handleJSONChange("customDrmPolicy")}
                        />
                    </Form.Field>
                    <ContingentButton
                        onClick={this.generateISPToken}
                        service={this.props.service}
                        module={this.props.module}
                        allPermissions={this.props.permissions}
                        scope="isp"
                        user={this.props.user}
                    > Generate </ContingentButton>
                </Form>
            </Container>

        );
    }
}

export default TokenISPPane;
