import React from "react";
import {Container, Form, Input} from "semantic-ui-react";
import AuthDataProvider from "../../../Services/AuthDataProvider";
import { v4 as uuidv4 } from 'uuid';
import ContingentButton from "../../ContingentButton";

class TokenATTV2Pane extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenId: uuidv4(),
            aud: "",
            sub: "",
            typ: "ID",
            soc: "",
            planName: "",
            accountId: "",
            iccid: "",
            model: "",
            vin: "",
            ctn: "",
            tenant: "",
            oemBrand: "",
            authTime: 0,
            azp: "",
            sessionState: "",
            acr: "",
            emailVerified: false,
            email:"",
            ttl: 300
        };
        AuthDataProvider.init({baseURL: process.env.SYNAPSE_CORE_URL});
    }

    generateAttToken = () => {
        let userId = null;
        try{
            console.log("generateAttToken Started...")
            userId = this.props.user.email || null;

            if (!userId) {
                throw "No selected route and/or user can not be identified"
            }

            let ttl = parseInt(this.state.ttl);
            if (ttl > 86400) {
                const confirmation = confirm("You are about to create a Token with a really long expiration, this is very insecure and if leaked could adversely affect our brands.");
                if (confirmation !== true) {
                    return;
                }
            }
            if (ttl < 300 || !ttl) {
                ttl = 300;
            }

            const now = new Date().getTime();
            const nowSeconds = now/1000;
            const body = {
                auth_time: parseInt(this.state.authTime),
                jti: this.state.tokenId,
                iss: "synapse",
                aud: this.state.aud,
                azp: this.state.azp,
                typ: this.state.typ,
                sub: this.state.sub,
                acr: this.state.acr,
                year: this.state.year,
                soc: this.state.soc,
                planName: this.state.planName,
                accountId: this.state.accountId,
                iccid: this.state.iccid,
                model: this.state.model,
                vin: this.state.vin,
                ctn: this.state.ctn,
                tenant: this.state.tenant,
                oemBrand: this.state.oemBrand,
                session_state: this.state.sessionState,
                email: userId,
                email_verified: true,
                iat: nowSeconds,
                exp: nowSeconds + ttl,
                timestamp: nowSeconds.toString(),
                expiryTime: (nowSeconds + ttl).toString()
            };

            AuthDataProvider.getAttToken(body)
                .then(resp => {
                resp.json().then(payload => {
                    this.props.onFormSubmit(payload);
                }).catch(error =>{
                    this.props.toast(error)
                });
            });
        } catch (error) {
            console.log(error);
            this.props.toast("Getting Payload failed")
        }
    };

    handleChange = id => event => {
        let text = event.target.value;
        this.setState({
            [id]: text
        });
    };

    handleEmailVerified = () => event => {
        this.setState({
            emailVerified: event.target.checked
        });
    };

    render() {
        return (
            <Container fluid style={{maxHeight: "82vh", overflowY: "auto", overflowX: "hidden"}}>
                <Form>
                    <Form.Field>
                        <label>Generate AT&T Token</label>
                    </Form.Field>
                    <Form.Field>
                        <label>JTI</label>
                        <input
                            required
                            type="text"
                            placeholder="*JTI"
                            onChange={this.handleChange("tokenId")}
                            defaultValue={this.state.tokenId}
                            id="tokenId"/>
                    </Form.Field>
                    <Form.Field
                        control={Input}
                        label="Auth Time"
                        value={this.state.authTime}
                        onChange={(event, {value}) => this.setState({authTime: value})}
                        type="number"
                    />
                    <Form.Field>
                        <label>aud</label>
                        <input
                            required
                            type="text"
                            placeholder="Audience"
                            onChange={this.handleChange("aud")}
                            id="audience"/>
                    </Form.Field>
                    <Form.Field>
                        <label>sub</label>
                        <input
                            required
                            type="text"
                            placeholder="*Subject"
                            onChange={this.handleChange("sub")}
                            id="subject"/>
                    </Form.Field>
                    <Form.Field>
                        <label>typ</label>
                        <input
                            required
                            type="text"
                            placeholder="Token Type"
                            onChange={this.handleChange("typ")}
                            id="tokenType"/>
                    </Form.Field>
                    <Form.Field>
                        <label>azp</label>
                        <input
                            required
                            type="text"
                            placeholder="Authorized Party"
                            onChange={this.handleChange("azp")}
                            id="authorizedParty"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Session State</label>
                        <input
                            required
                            type="text"
                            placeholder="Session State"
                            onChange={this.handleChange("sessionState")}
                            id="sessionState"/>
                    </Form.Field>
                    <Form.Field>
                        <label>acr</label>
                        <input
                            required
                            type="text"
                            placeholder="Authentication Context Class"
                            onChange={this.handleChange("acr")}
                            id="authContextClass"/>
                    </Form.Field>
                    <Form.Field>
                        <label>iccid</label>
                        <input
                            required
                            type="text"
                            placeholder="ICC ID"
                            onChange={this.handleChange("iccid")}
                            id="iccId"/>
                    </Form.Field>
                    <Form.Field>
                        <label>model</label>
                        <input
                            required
                            type="text"
                            placeholder="Model"
                            onChange={this.handleChange("model")}
                            id="model"/>
                    </Form.Field>
                    <Form.Field>
                        <label>vin</label>
                        <input
                            required
                            type="text"
                            placeholder="VIN"
                            onChange={this.handleChange("vin")}
                            id="vin"/>
                    </Form.Field>
                    <Form.Field>
                        <label>ctn</label>
                        <input
                            required
                            type="text"
                            placeholder="CTN"
                            onChange={this.handleChange("ctn")}
                            id="ctn"/>
                    </Form.Field>
                    <Form.Field>
                        <label>tenant</label>
                        <input
                            required
                            type="text"
                            placeholder="Tenant"
                            onChange={this.handleChange("tenant")}
                            id="tenant"/>
                    </Form.Field>
                    <Form.Field>
                        <label>OEM Brand</label>
                        <input
                            required
                            type="text"
                            placeholder="Brand"
                            onChange={this.handleChange("oemBrand")}
                            id="oemBrand"/>
                    </Form.Field>
                    <Form.Field>
                        <label>soc</label>
                        <input
                            required
                            type="text"
                            placeholder="soc"
                            onChange={this.handleChange("soc")}
                            id="soc"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Plan Name</label>
                        <input
                            required
                            type="text"
                            placeholder="Plan"
                            onChange={this.handleChange("planName")}
                            id="planName"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Account ID</label>
                        <input
                            required
                            type="text"
                            placeholder="Account"
                            onChange={this.handleChange("accountId")}
                            id="accountId"/>
                    </Form.Field>
                    <Form.Field>
                        <label>TTL</label>
                        <select id="ttlDropDown" onChange={this.handleChange("ttl")}>
                            <option value="300">5 Minutes</option>
                            <option value="3600">1 Hour</option>
                            <option value="7200">2 Hours</option>
                            <option value="28800">8 Hours</option>
                            <option value="86400">1 Day</option>
                            <option value="604800">7 Days</option>
                        </select>
                    </Form.Field>
                    <ContingentButton
                        onClick={this.generateAttToken}
                        allPermissions={this.props.permissions}
                        module={this.props.module}
                        scope="att"
                        service={this.props.service}
                        user={this.props.user}
                    > Generate </ContingentButton>
                    <br/>
                </Form>
            </Container>
        );
    }
}

export default TokenATTV2Pane
