import React from "react";
import {Button, Form, TextArea} from "semantic-ui-react";


class TokenAssetsPane extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assetIdsClob: "",
            mediaIdsClob: "",
            odtAiringIdsClob: ""
        };
    }

    getAssets = () => {
        let userId = null;
        try {
            userId = this.props.user.sub || null;

            if (!userId) {
                throw "No selected route and/or user can not be identified";
            }

            const assetIds = this.state.assetIdsClob.split("\n");
            const mediaIds = this.state.mediaIdsClob.split("\n");
            const odtAiringIds = this.state.odtAiringIdsClob.split("\n");

            const payload = {
                action: "GET",
                assetIds: assetIds,
                mediaIds: mediaIds,
                odtAiringIds: odtAiringIds
            }

            this.props.TokenAdminDataProvider.getAssets(payload)
                .then(resp => {
                    resp.json().then(payload => {
                        console.log(payload);
                        this.props.onFormSubmit(payload);
                    }).catch(error =>{
                        this.props.toast("Getting Payload failed");
                        console.log(error);
                    });
                });
        } catch(error) {
            console.log(error);
            this.props.toast("Getting Payload failed");
        }
    };

    render() {
        return (
            <Form>
                <Form.Field>
                    <label>Get Token Assets</label>
                </Form.Field>
                <Form.Field
                    label="Asset IDs"
                    control={TextArea}
                    placeholder="Please Place All Your Asset IDs separated by a new line(enter)"
                    value={this.state.assetIdsClob}
                    onChange={(event, {value}) => this.setState({assetIdsClob: value})}
                    rows={8}
                />
                <Form.Field
                    label="Media IDs"
                    control={TextArea}
                    placeholder="Please Place All Your Media IDs separated by a new line(enter)"
                    value={this.state.mediaIdsClob}
                    onChange={(event, {value}) => this.setState({mediaIdsClob: value})}
                    rows={8}
                />
                <Form.Field
                    label="ODT Airing IDs"
                    control={TextArea}
                    placeholder="Please Place All Your ODT Airing IDs separated by a new line(enter)"
                    value={this.state.odtAiringIdsClob}
                    onChange={(event, {value}) => this.setState({odtAiringIdsClob: value})}
                    rows={8}
                />
                <Button onClick={this.getAssets}> Get </Button>
                <br/>
            </Form>
        );
    }
}

export default TokenAssetsPane;
